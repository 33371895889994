<template>
  <form @submit.prevent="onSubmit" class="form-admin-role">
    <div class="m-auto w-96 flex flex-col py-8">
      <div class="flex">
        <input v-model="email" :class="inputEmailClass" id="role_user_email" type="text" placeholder="Email" @keydown.space.prevent>
        <button :class="buttonAddClass" type="submit">Add</button>
      </div>
      <span v-if="errorEmailEmpty" class="text-red-600 mt-2 ml-2">Email is required</span>
      <span v-if="errorMessage" class="text-red-600 mt-2 ml-2">{{errorMessage}}</span>
    </div>
  </form>

  <ul v-if="users.length>0" :class="listUsersClass">
    <li v-for="user in users" :key="user.id" class="flex justify-between items-center py-2 px-4 w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
      <div>
        <span class="font-bold">{{user.first_name}} {{user.last_name}}</span><br>({{user.email}})
      </div>
      <button :class="buttonDeleteClass" type="button" @click="showDeleteModal(user.id)">Delete</button>
    </li>
  </ul>
  <div v-else class="m-auto text-center">
    <span>No user found</span>
  </div>

  <delete-user ref="modalDeleteRef" @deleteLanguage="onDelete"/>
</template>

<script>
import {computed, ref, toRefs} from "vue";
import {Spinner} from "@/core/utils"
import {ProjectServices} from "@/modules/Project/Services"
import DeleteUser from "@/modules/Project/Components/DeleteUser";

export default {
  name: "RolesManager",
  components: {DeleteUser},
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isLanguageEditor: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: [String, Number],
      default: "",
    },
    languageId: {
      type: [String, Number],
      default: "",
    },
    facilityId: {
      type: [String, Number],
      default: "",
    },
  },
  setup(props) {
    const email = ref(null)
    const userId = ref(null)
    const users = ref([])
    const modalDeleteRef = ref(null)
    const { isAdmin, isLanguageEditor, projectId, languageId, facilityId } = toRefs(props)
    const project_id = ref(parseInt(projectId.value))
    const language_id = ref(parseInt(languageId.value))
    const facility_id = ref(parseInt(facilityId.value))
    const errorEmailEmpty = ref(false)
    const errorMessage = ref(null)

    const inputEmailClass = computed(() => {
      return 'mr-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
    })

    const buttonAddClass = computed(() => {
      return 'self-end w-1/4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
    })

    const buttonDeleteClass = computed(() => {
      return 'w-1/8 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
    })

    const listUsersClass = computed(() => {
      return 'm-auto w-4/6 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white'
    })

    const getUsers = async () => {
      try {
        Spinner.start()
        resetErrorMessage()
        if (isAdmin.value) {
          users.value = await ProjectServices.getAdministratorUsers(project_id.value, facility_id.value)
        }
        if (isLanguageEditor.value) {
          users.value = await ProjectServices.getLangEditorUsers(project_id.value, facility_id.value, language_id.value)
        }
      } catch (e) {
        console.error(e)
      } finally {
        Spinner.stop()
      }
    }

    const resetErrorMessage = () => {
      errorMessage.value = null
    }

    const onSubmit = async () => {
      try {
        Spinner.start()
        resetErrorMessage()
        if (!email.value) {
          errorEmailEmpty.value = true
        } else {
          errorEmailEmpty.value = false
          const data = {user_id: email.value}

          if (isAdmin.value) {
            users.value = await ProjectServices.addAdministratorUser(project_id.value, facility_id.value, data);
          }

          if (isLanguageEditor.value) {
            users.value = await ProjectServices.addLangEditorUsers(project_id.value, language_id.value, facility_id.value, data);
          }

          email.value = null
        }
      } catch (e) {
        console.error(e)
        errorMessage.value = e
      } finally {
        Spinner.stop()
      }
    }

    const onDelete = async () => {
      try {
        Spinner.start()
        if (isAdmin.value) {
          await ProjectServices.deleteAdministratorUser(project_id.value, facility_id.value, userId.value);
        }
        if (isLanguageEditor.value) {
          await ProjectServices.deleteLangEditorUser(projectId.value, language_id.value, facility_id.value, userId.value);
        }
        await getUsers()
        hideDeleteModal()
      } catch (e) {
        console.error(e)
      } finally {
        Spinner.stop()
      }
    }

    const showDeleteModal = (user_id) => {
      userId.value = user_id
      modalDeleteRef.value.showModal()
    };

    const hideDeleteModal = () => {
      userId.value = null
      modalDeleteRef.value.hideModal()
    }

    getUsers()

    return {
      email,
      userId,
      users,
      modalDeleteRef,
      errorEmailEmpty,
      errorMessage,
      inputEmailClass,
      buttonAddClass,
      buttonDeleteClass,
      listUsersClass,
      showDeleteModal,
      onSubmit,
      onDelete
    }
  }
}
</script>

<style scoped>

</style>
