<template>
  <div class="wrapper-breadcrumb py-5">
    <div class="container flex items-align justify-between mx-auto">
      <router-link :to="{name: 'project-page'}" class="breadcrumb-link">
        {{ project ? project.name + ' / Manage Administrators' : 'Go to Dashboard' }}
      </router-link>
    </div>
  </div>

  <roles-manager
    is-admin
    :project-id="projectId"
    :facility-id="facilityId"
  />
</template>

<script>
import {ref} from "vue"
import {Spinner} from "@/core/utils"
import {ProjectServices} from "@/modules/Project/Services"
import {useRoute, useRouter} from "vue-router"
import {RolesManager} from "../Components";

export default {
  name: "ProjectAdminRolePage",
  components: {
    RolesManager
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const project = ref({})
    const projectId = ref(route.params.id)
    const facilityId = ref(route.params.facilityId)
    const userId = ref(null)

    const getProject = async () => {
      try {
        Spinner.start()
        project.value = await ProjectServices.getProject(projectId.value, facilityId.value)
      } catch (e) {
        console.error(e)
        goToDashboardPage()
      } finally {
        Spinner.stop()
      }
    }

    const goToDashboardPage = () => {
      router.push({
        name: 'dashboard-page'
      })
    }

    getProject()

    return {
      projectId,
      project,
      userId,
      facilityId,
    }
  }
}
</script>

<style scoped lang="stylus">
</style>
