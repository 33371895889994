<template>
  <Modal ref="modalRef">
    <template v-slot:modalContent>
      <p class="text-center font-bold mb-3">Import Translations to {{ language.culture_display_name }} ({{ language.culture_name }})</p>
      <div class="common-form-group md:mr-2">
        <label class="common-label-input block text-sm font-medium text-gray-900 dark:text-gray-300" for="file_input_audio">Upload File*</label>
        <input class="mb-1 block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" @change="setFile" ref="fileRef" id="file_input_file" type="file" accept="text/xliff">
      </div>
      <div class="flex justify-end items-center container-modal-btns">
        <button @click="hideModal" class="common-btn common-btn--secondary mr-3">Cancel</button>
        <button @click="handleImport" class="common-btn">Import</button>
      </div>
      <span class="text-red-600" v-if="error">{{ error }}</span>
    </template>
  </Modal>
</template>

<script>

import {Modal} from '@/core/components'
import {ref} from "vue";
import {ProjectServices} from "@/modules/Project/Services";
import {Utils, Spinner} from "@/core/utils";

export default {
  name: "ImportModal",
  components: {Modal},
  props: {
    projectId: {
      type: [String, Number],
      default: "",
      required: true
    },
    facilityId: {
      type: [String, Number],
      default: null,
    },
    language: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  setup(props, {emit}) {
    const modalRef = ref(null);
    const fileRef = ref(null)
    const file = ref(null);
    const error = ref(null)

    const setFile = () => {
      file.value = fileRef.value.files[0]
    }

    const reset = () => {
      error.value = null
    }

    const showModal = () => {
      modalRef.value.showModal();
    };

    const hideModal = () => {
      modalRef.value.hideModal();
    };

    const handleImport = async () => {
      try {
        Spinner.start()
        const formData = Utils.getFormData({file: file.value})
        await ProjectServices.import(props.projectId, props.language.id, props.facilityId, formData)
        reset()
        hideModal()
        emit('onSuccess');
      } catch (e) {
        error.value = e.message;
      } finally {
        Spinner.stop()
      }

    }

    return {
      fileRef,
      modalRef,
      hideModal,
      showModal,
      setFile,
      handleImport,
      error
    }
  }
}
</script>

<style scoped>

</style>
