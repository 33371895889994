
function getRandomState() {
  let state = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 16; i++) {
    state += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return state;
}

function getCallbackParameters(href) {
  const url = new URL(href);
  const fixedUrlString = url.href.replace("/callback/#", "/callback?");
  const fixedUrl = new URL(fixedUrlString);
  const expires_in = fixedUrl.searchParams.get('expires_in');
  const id_token = fixedUrl.searchParams.get("id_token");
  const access_token = fixedUrl.searchParams.get('access_token');
  const scope = fixedUrl.searchParams.get('scope');
  const state = fixedUrl.searchParams.get('state');
  return {
    expires_in,
    id_token,
    access_token,
    scope,
    state
  }
}

export default {
  getRandomState,
  getCallbackParameters
}
