import {http} from '@/http'

const resource = "/api/admin";

export default {
  getProjectList() {
    return http.get(`${resource}/application/`)
  },
  addProject(payload) {
    return http.post(`${resource}/application/`, payload)
  },
  editProject(projectId, payload) {
    return http.put(`${resource}/application/${projectId}`, payload)
  }
}
