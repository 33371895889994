<template>
  <Modal ref="modalRef" modal-title="Are you sure you want to delete this user?">
    <template v-slot:modalContent>
      <div class="flex justify-end items-center container-modal-btns">
        <button @click="hideModal" class="common-btn common-btn--secondary mr-3">Cancel</button>
        <button class="common-btn" @click="onDelete">Delete</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { ref } from 'vue'
import { Modal } from '@/core/components'

export default {
  name: "DeleteUser",
  components: { Modal },
  setup(props, { emit }) {

    const modalRef = ref(null);

    const showModal = () => {
      modalRef.value.showModal();
    };

    const hideModal = () => {
      modalRef.value.hideModal();
    };

    const onDelete = () => {
      emit('deleteLanguage');
    }

    return {
      modalRef,
      showModal,
      hideModal,
      onDelete
    }
  }
}
</script>

<style scoped>

</style>
