<template>
  <div class="min-h-screen">
    <Spinner/>
    <HeaderApp/>
    <slot name="content"></slot>
  </div>
</template>

<script>
  import HeaderApp from "./HeaderApp.vue"
  import { Spinner } from "../Spinner"
  export default {
    name: "PageBase",
    components: {
      HeaderApp,
      Spinner
    }
  };
</script>

<style lang="stylus" scoped>
</style>
