import ProjectView from "../ProjetcView.vue"
import ProjectPage from "../Pages/ProjetcPage.vue"
import ProjectLanguagePage from "../Pages/ProjectLanguagePage.vue"
import ProjectAdminRolePage from "../Pages/ProjectAdminRolePage.vue";
import ProjectLangEditorRolePage from "@/modules/Project/Pages/ProjectLangEditorRolePage";

const routes = [
  {
    path: '/project',
    component: ProjectView,
    children: [
      {
        path: ':id',
        name: 'project-page',
        component: ProjectPage,
        meta: {authRequired: true},
      },
      {
        path: ':id/manage-admin/facility/:facilityId',
        name: 'admin-role-page',
        component: ProjectAdminRolePage,
        meta: {authRequired: true},
      },
      {
        path: ':id/edit-language/:languageId/facility/:facilityId',
        name: 'language-page',
        component: ProjectLanguagePage,
        meta: {authRequired: true},
      },
      {
        path: ':id/edit-language/:languageId/manage-lang-editor/facility/:facilityId',
        name: 'lang-editor-role-page',
        component: ProjectLangEditorRolePage,
        meta: {authRequired: true},
      }
    ]
  }
]

export default routes
