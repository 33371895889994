<template>
  <div class="py-12">
    <div class="container mx-auto">
      <div class="grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:gap-4 lg:grid-cols-4">
        <add-new-project v-if="isSuperAdmin" @onAddNewProject="addProject"/>

        <project-card
          v-for="(project, index) in projects"
          :key="`project-card-id-${project.id}`"
          :project="project" :project-index="index"
          @toggleModal="toggleEditProjectModal"
        />
      </div>
    </div>
    <add-edit-project-modal
      ref="editProjectModalRef"
      is-edit
      @onEditProject="onEditProject"
    />
  </div>
</template>

<script>

import {ref, onMounted, computed} from 'vue'
import { DashboardServices } from "../Services"
import { ProjectCard, AddNewProject, AddEditProjectModal } from "../Components"
import {Storage} from "@/core/utils";
import {Constants} from "@/core/utils";

  export default {
    name: 'DashboardPage',
    components: {
      ProjectCard,
      AddNewProject,
      AddEditProjectModal
    },
    setup() {

      const projects = ref([]);
      const editProjectModalRef = ref(null);
      const userRole = ref(null);

      const isSuperAdmin = computed(() => {
        return userRole.value === Constants.USER_ROLES.SUPER_ADMINISTRATOR
      })

      const getProjects = async () => {
        try {
          projects.value = await DashboardServices.getProjectList();
        } catch (e) {
          console.error(e)
        }
      }

      const addProject = (newApp) => {
        projects.value.unshift(newApp)
      }

      const toggleEditProjectModal = (show, project, pIndex) => {
        if (show){
          editProjectModalRef.value.showModal(project, pIndex);
        } else {
          editProjectModalRef.value.hideModal();
        }
      };

      const onEditProject = (projectIndex, project) => {
        projects.value[projectIndex] = project
      };

      onMounted(async () => {
        await getProjects()
        const accessToken = Storage.getAccessToken();
        if (accessToken) {
          const user = Storage.getUser();
          userRole.value = user.role_code;
        }
      })

      return {
        isSuperAdmin,
        projects,
        editProjectModalRef,
        addProject,
        toggleEditProjectModal,
        onEditProject
      }
    }
  }
</script>

<style scoped lang="stylus">
  @import "../Styles/dashboard.styl";
</style>
