<template>
  <Modal ref="modalRef" @onHideModal="resetErrorMessage">
    <template v-slot:modalContent>
      <p class="text-center font-bold">{{ translation.key }}</p>
      <div class="common-form-group md:mr-2">
        <label class="common-label-input" :class="labelClass" for="file_input_audio">Upload Audio*</label>
        <input class="mb-1" :class="inputClass" @change="setAudioFile" ref="audioRef"  id="file_input_audio" type="file" accept="audio/mpeg">
        <label class="common-label-input" :class="labelClass" for="file_input_subtitle">Upload Subtitles</label>
        <input :class="inputClass" @change="setSubtitleFile" ref="subtitleRef" id="file_input_subtitle" type="file" accept="text/vtt, text/plain">
      </div>
      <div class="flex justify-end items-center container-modal-btns">
        <button @click="hideModal" class="common-btn common-btn--secondary mr-3">Cancel</button>
        <button class="common-btn" @click="updateTranslation">Edit</button>
      </div>
      <span class="text-red-600" v-if="audioIsRequired">Audio File is required</span>
      <span class="text-red-600" v-if="errorMessage">{{errorMessage}}</span>
    </template>
  </Modal>
</template>

<script>
import { Modal } from '@/core/components'
import {computed, ref} from "vue";
import {ProjectServices} from "@/modules/Project/Services";
import { Utils, Spinner } from "@/core/utils"

export default {
  name: "EditModal",
  components: { Modal },
  props: {
    translation: {
      type: Object,
      default: () => {
        return {}
      }
    },
    projectId: {
      type: [String, Number],
      default: "",
      required: true
    },
    facilityId: {
      type: [String, Number],
      default: null,
    },
    languageId: {
      type: [String, Number],
      default: "",
      required: true
    },
  },
  setup(props, { emit }) {
    const modalRef = ref(null);
    const audio = ref(null);
    const audioRef = ref(null);
    const subtitle = ref(null);
    const subtitleRef = ref(null);
    const audioIsRequired = ref(false);
    const errorMessage = ref(null);
    const labelClass = computed(() => {
      return 'block text-sm font-medium text-gray-900 dark:text-gray-300';
    })

    const inputClass = computed(() => {
      return 'block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400';
    })

    const setAudioFile = () => {
      audio.value = audioRef.value.files[0]
    }

    const setSubtitleFile = () => {
      subtitle.value = subtitleRef.value.files[0]
    }

    const resetForm = () => {
      audio.value = null;
      subtitle.value = null;
    };

    const resetErrorMessage = () => {
      audioIsRequired.value = false
      errorMessage.value = null
    }

    const showModal = () => {
      modalRef.value.showModal();
    };

    const hideModal = () => {
      modalRef.value.hideModal();
      resetErrorMessage()
    };

    const onOkClick = () => {
      emit('onOkClick');
    }

    const updateTranslation = async () => {
      try {
        Spinner.start()
        let data = {
          id: props.translation.id,
          term_id: props.translation.term_id,
          term: props.translation.key
        };
        data.audio_file = audio.value;
        data.subtitle_file = subtitle.value;
        data.audio_id = props.translation.audio_id;
        data.subtitle_id = props.translation.subtitle_id;
        if(data.audio_id || (!data.audio_id && data.audio_file)) {
          resetErrorMessage()
          const formData = Utils.getFormData(data)
          const response = await ProjectServices.addUpdateTranslationWithAudio(props.projectId, props.languageId, props.facilityId, formData)
          emit('onUpdateTranslation',response);
          resetForm();
          hideModal();
        } else {
          audioIsRequired.value = true
        }
      } catch (error) {
        console.error(error)
        errorMessage.value = error;
      } finally {
        Spinner.stop()
      }
    };

    return {
      audioIsRequired,
      errorMessage,
      resetErrorMessage,
      audio,
      audioRef,
      subtitle,
      subtitleRef,
      modalRef,
      labelClass,
      inputClass,
      setAudioFile,
      setSubtitleFile,
      showModal,
      hideModal,
      onOkClick,
      updateTranslation
    }
  }
}
</script>

<style scoped>

</style>
