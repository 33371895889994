<template>
  <div>
    <div class="py-32" v-if="error">
      <div class="flex items-center justify-center">
        <div class="bg-white rounded-xl justify-between p-32 py-20 mx-2">
          <div class="flex flex-col items-center md:w-auto">
            <div class="common-error-message mt-2">{{ error }}</div>
            <button class="common-btn" @click="goToLogin">GO TO LOGIN</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, onBeforeMount} from 'vue';
import {useRouter} from "vue-router";
import {useAuth} from '@okta/okta-vue';
import {SecurityService} from "@/modules/Security/Services";
import {Storage} from "@/core/utils";

export default {
  name: 'LoginCallback',
  setup() {
    const router = useRouter()
    const error = ref(null);
    const $auth = useAuth();

    const goToLogin = () => {
      router.push({name: 'login-page'});
    }

    const loginSSOService = async (data) => {
      const response = await SecurityService.loginSSO(data);
      Storage.setAccessToken(response.access_token)
      Storage.setUser(response.user);
    }

    onBeforeMount(async () => {
      try {
        await $auth.handleLoginRedirect();
        const accessToken = await $auth.tokenManager.get('accessToken');
        const userInfo = await $auth.token.getUserInfo();

        const data = {
          token: {
            access_token: accessToken.accessToken,
            expires_in: 1, // dummy value
            expires_at: accessToken.expiresAt
          },
          user: {
            email: userInfo.email,
            role: [],
            given_name: userInfo.given_name,
            family_name: userInfo.family_name,
            sub: userInfo.sub,
          },
          is_okta_sso: true
        };
        await loginSSOService(data);

        await router.push({name: 'dashboard-page'});

      } catch (e) {
        const isInteractionRequiredError = $auth.isInteractionRequiredError || $auth.idx.isInteractionRequiredError;
        if (isInteractionRequiredError(e)) {
          const {onAuthResume, onAuthRequired} = $auth.options;
          const callbackFn = onAuthResume || onAuthRequired;
          if (callbackFn) {
            callbackFn($auth);
            return;
          }
        }
        if (e) {
          error.value = e.toString();
        } else {
          error.value = 'Unexpected error, please try again.';
        }
      }
    });

    return {
      error,
      goToLogin
    };
  }
}
</script>
