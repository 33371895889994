<template>
  <modal ref="modalRef" modal-title="Add Facilities">
    <template v-slot:modalContent>
      <input
        class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        type="text"
        v-model="facilityInput"
      />
      <div class="flex justify-end items-center container-modal-btns">
        <button @click="hideModal" class="common-btn common-btn--secondary mr-3">Cancel</button>
        <button type="submit" class="common-btn" :disabled="inputLengthIsZero" @click="handleSave">Save</button>
      </div>
    </template>
  </modal>
</template>

<script>
import { ref, computed } from "vue";
import { Modal } from '@/core/components'
export default {
  name: "AddFacilitiesModal",
  components: {
    Modal,
  },
  setup(_, {emit}) {
    const modalRef = ref(null);
    const facilityInput = ref('');
    const showMessageSuccessfully = ref(false);

    const inputLengthIsZero = computed(() => {
      return facilityInput.value.length === 0;
    })

    const showModal = () => {
      modalRef.value.showModal();
    };

    const hideModal = () => {
      facilityInput.value = '';
      showMessageSuccessfully.value = false;
      modalRef.value.hideModal();
    }

    const handleSave = () => {
      try {
        emit('on-save-facility', facilityInput.value);
        facilityInput.value = '';
      } catch (e) {
        console.error = e;
      }
    }

    return {
      modalRef,
      facilityInput,
      handleSave,
      showModal,
      hideModal,
      inputLengthIsZero,
      showMessageSuccessfully,
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"
</style>
