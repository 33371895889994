<template>
    <page-base>
        <template v-slot:content>
            <router-view></router-view>
        </template>
    </page-base>
</template>

<script>
    import { PageBase } from '@/core/components'

  export default {
    name: "SecurityView",
      components: { PageBase }

  };
</script>


