export default {
  validDomain: (value,) => {
    if (!value) return true;
    const regx = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9](:[0-9]{1,5})?$/
    return regx.test(value)
  },

  getFormData: (data) => {
    const formData = new FormData();
    for (const key in data) {
      if(Array.isArray(data[key]) && data[key].length > 0){
        for (const index in data[key]){
          const dataKeyIndex = data[key][index];
          if( typeof dataKeyIndex === 'string' || typeof dataKeyIndex === 'number' ) {
            formData.append(`${key}[${index}]`, data[key][index]);
          } else if ( dataKeyIndex instanceof File ) {
            formData.append(`${key}`, data[key][index]);
          } else {
            for (const key2 in data[key][index]) {
              formData.append(`${key}[${index}].${key2}`, data[key][index][key2] ? data[key][index][key2] : '');
            }
          }
        }
      } else {
        formData.append(key, data[key] ? data[key] : data[key] == null ? '' : data[key]);
      }
    }
    return formData;
  }
}