<template>
  <div class="translation-row flex flex-col items-start md:flex-row p-5">
    <div class="mr-3 mb-3 md:mb-0" :class="{'md:mt-3': isDefaultLang}">
      <label class="custom-checkbox">
        <span v-if="isVisible">
          <input type="checkbox" @change="onSelectCheckbox" :id="`checkbox_term_${translation.term_id}`">
          <span class="c-checkbox block">
            <span class="box-checkbox block"></span>
          </span>
        </span>
      </label>
    </div>
    <div class="w-full md:max-w-col-term md:mr-12 md:min-w-col-term mb-3 md:mb-0">
      <translation-input
        is-key
        :is-default-lang="isDefaultLang"
        :translation="translation"
        :is-editable="isVisible"
        @onUpdateTranslation="onUpdateTranslation"
      />
    </div>
    <div class="w-full md:max-w-col-term md:min-w-col-term">
      <translation-input
        :is-key="false"
        :is-default-lang="isDefaultLang"
        :translation="translation"
        @updateTranslation="onUpdateTranslation"
        @onUpdateTranslation="onUpdateTranslation"
      />
    </div>
  </div>
</template>

<script>
  import { computed, inject } from 'vue';
  import TranslationInput from './TranslationInput.vue'

  export default {
    name: 'TranslationRow',
    props: {
      translation: {
        type: Object,
        default: () => {
          return {}
        }
      },
      isDefaultLang: {
        type: Boolean,
        default: false
      }
    },
    components: { TranslationInput },
    setup(props, { emit }) {
      const facilityId = inject('facilityId');

      const isVisible = computed(() => {
        return props.translation.facility_id === parseInt(facilityId);
      })
      const onUpdateTranslation = (translation) => {
        emit('updateTranslation', translation);
      };

      const onSelectCheckbox = () => {
        emit('onSelectCheckbox', props.translation);
      };

      return {
        isVisible,
        onUpdateTranslation,
        onSelectCheckbox
      }
    }
  }
</script>

<style scoped lang="stylus">
</style>
