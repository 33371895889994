<template>
  <div class="common-form-group">
    <div v-if="isDefaultLang || (!isDefaultLang && !isKey)">
      <div class="common-label-input" v-if="!isDefaultLang && !isAudioList">Translation</div>
      <div
        v-if="!showInput && value"
        class="common-input show-input-onfocus"
        :class="inputClass"
        @click="toggleInput(true)">
        {{ value }}
      </div>
      <div class="flex justify-between items-center" v-else-if="!showInput">
        <a v-if="translation.audio_url" class="text-blue-500 file-input" :href="translation.audio_url" target="_blank">{{ translation.audio_name }}</a>
        <a v-if="translation.subtitle_url" class="text-blue-500 file-input" :href="translation.subtitle_url" target="_blank">{{ translation.subtitle_name }}</a>
        <span v-if="!translation.audio_url && !translation.subtitle_url" class="file-input">No translation</span>
        <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" @click="showEditModal">Edit</button>
        <edit-modal ref="modalEditRef"
                    :translation="translation"
                    :project-id="projectId"
                    :language-id="languageId"
                    :facility-id="facilityId"
                    @onUpdateTranslation="onUpdate"/>
      </div>
      <textarea v-show="(showInput)" class="common-input is-textarea key-term-textarea"
                v-model="value"
                @change="onchange"
                :id="setIdTextarea()"
                @blur="onBlurTextarea">
      </textarea>
      <div class="common-error-message" v-if="errorMessage">{{ errorMessage }}</div>
    </div>
    <div v-else class="text-label-term md:ml-4">
      <div :class="{'mb-1': translation.default_value}">{{ translation.default_value }}</div>
      <div class="text-label-term--key">{{ translation.key }}</div>
    </div>
  </div>
</template>

<script>

  import { ref, onMounted, nextTick, watch, inject, computed } from 'vue'
  import { ProjectServices } from "../Services"
  import EditModal from "@/modules/Project/Components/EditModal";
  import { Utils } from "@/core/utils"

  export default {
    name: 'TranslationInput',
    components: {EditModal},
    props: {
      translation: {
        type: Object,
        default: () => {
          return {}
        }
      },
      isDefaultLang: {
        type: Boolean,
        default: false
      },
      isKey: {
        type: Boolean,
        default: true,
      },
      isEditable: {
        type: Boolean,
        default: true,
      }
    },
    setup(props, { emit }) {
      const value = ref("");
      const errorMessage = ref("");
      const showInput = ref(false);
      const modalEditRef = ref(null);

      const projectId = inject('projectId');
      const languageId = inject('languageId');
      const facilityId = inject('facilityId');
      const isAudioList = inject('isAudioList');

      const inputClass = computed(() => {
        return {
          'cursor-default': !props.isEditable,
        }
      })

      const resetErrorMessages = () => {
        errorMessage.value = "";
      };

      const toggleInput = (show) => {
        if(props.isDefaultLang){
          showInput.value = show && props.isEditable;
          if (show) {
            nextTick(() => {
              const $textarea = document.getElementById(setIdTextarea());
              $textarea.focus();
            });
          }
        }
      };

      const validateShowInput = () => {
        if(props.isDefaultLang === true){
          if((value.value && value.value.length > 0 && !errorMessage.value) || isAudioList){
            showInput.value = false;
          }else if(!value.value || errorMessage.value){
            showInput.value = true;
          }
        }else showInput.value = !isAudioList;
      }

      const onBlurTextarea = () => {
        nextTick(() => {
          validateShowInput();
        });
      }

      const onUpdate = (translation) => {
        emit('updateTranslation', translation);
      };

      const updateTranslation = async () => {
        try {
          resetErrorMessages();

          let data = {
            id: props.translation.id,
            term_id: props.translation.term_id
          };

          if(!isAudioList) {
            if (props.isKey) {
              data.key = value.value;
              data.value = props.translation.value;
            } else {
              data.key = props.translation.key;
              data.value = value.value;
            }
          } else {
            if (props.isKey) {
              data.term = value.value;
            } else {
              data.audio_file = '';
              data.subtitle_file = '';
            }
          }
          let response = null;
          if(!isAudioList) {
            response = await ProjectServices.addUpdateTranslation(projectId, languageId, facilityId, data)
          } else {
            const formData = Utils.getFormData(data)
            response = await ProjectServices.addUpdateTranslationWithAudio(projectId, languageId, facilityId, formData)
          }
          validateShowInput();
          emit('onUpdateTranslation', response);
        } catch (error) {
          if(error){
            errorMessage.value = error;
          } else {
            errorMessage.value = "Unexpected error, please try again.";
          }
          validateShowInput();
        }
      };

      const onchange = () => {
        updateTranslation();
      };

      const setInitialValue = () => {
        value.value = props.isKey ? props.translation.key : props.translation.value;
      };

      const setIdTextarea = () => {
        return props.isKey ? `textarea-term-${props.translation.term_id}` : `textarea-translation-${props.translation.term_id}`
      }

      onMounted(() => {
        setInitialValue();
        validateShowInput();
      })

      watch(() => props.isDefaultLang, () => {
        validateShowInput();
      });

      watch(() => props.translation.value, (val) => {
        if (!props.isKey) value.value = val
      })

      const showEditModal = () => {
        modalEditRef.value.showModal();
      };

      return {
        projectId,
        languageId,
        facilityId,
        isAudioList,
        value,
        errorMessage,
        onchange,
        setIdTextarea,
        showInput,
        toggleInput,
        onBlurTextarea,
        modalEditRef,
        showEditModal,
        onUpdate,
        inputClass,
      }
    }
  }
</script>

<style lang="stylus" scoped>
.file-input
  font font-sourcesanspro-regular
  font-size 16px
  padding 8px 15px
  min-height 40px
  word-break break-word
</style>
