<template>
  <div class="wrapper-language-list-page">
    <div class="wrapper-breadcrumb py-5">
      <div class="container flex items-align justify-between mx-auto">
        <router-link :to="{name: 'dashboard-page'}" class="breadcrumb-link">
          {{ project ? project.name : 'Go to Dashboard' }}
        </router-link>
        <button
          v-if="isSuperAdmin || (!isSuperAdmin && isNotNationalFacilityToAdminRole)"
          class="manage-list"
          @click="handleGoToAdministratorRoles">Administrator Roles</button>
      </div>
    </div>

    <div v-if="isSuccessImport" class="bg-blue-300 border-t-4 border-blue-600 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
      <div class="flex">
        <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
        <div>
          <p class="text-sm">was imported successfully.</p>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center">
      <div v-if="facilityList" class="p-2 flex flex-row facilities">
        <dropdown
          v-if="dropdownConditionals"
          label="Facilities"
          :options="facilityList"
          :default-val="facilityDefaultForDropdown"
          @onSelectOption="handleSelectOption"
        />
        <button
          v-if="isSuperAdmin || isAdmin && isMaster"
          class="facilities__button-add common-btn"
          @click="handleOpenFacilitiesModal">Add</button>
      </div>
      <span v-if="showSuccessMessageFacility" class="facilities__success-message text-green-500">
        Facility was successfully added
      </span>
      <span v-if="showFailedMessageFacility" class="text-red-600">
        {{ errorMessageFacility }}
      </span>
      <p></p>
    </div>


    <div class="container-languages-list py-10">
      <div class="container mx-auto">
        <div>
          <language-card
            v-if="!isLanguageEditor && project && facilitySelected"
            :language="defaultLanguage"
            :project-id="project.id"
            :facility-id="facilitySelected.id"
            is-default-lang
            :term-count="project.term_count ? project.term_count : 0"
            @onSuccessImport="onSuccessImport"
          />
        </div>
        <div class="mb-5" v-for="language in projectLanguages" :key="`language-card-${language.id}`">
          <language-card
            v-if="language.id !== defaultLanguage.id && facilitySelected && languageCardConditional(language.id)"
            :language="language"
            :project-id="project.id"
            :facility-id="facilitySelected.id"
            :is-default-lang="false"
            :is-language-editor="isLanguageEditor"
            @onDeleteLanguage="onDeleteLanguage"
            @onSuccessImport="onSuccessImport"
          />
        </div>

        <div>
          <add-new-language
            :is-language-editor="isLanguageEditor"
            :project="project"
            @addNewLanguage="addNewLanguage"
          />
        </div>

      </div>
    </div>
  </div>

  <add-facilities-modal
    ref="addFacilitiesModalRef"
    @on-save-facility="handleSaveFacility"
  />
</template>

<script>
import {ref, computed, onMounted} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useFacilityStore } from "@/store";
import {Spinner, Constants, Storage} from "@/core/utils"
import { ProjectServices } from "../Services"
import { AddNewLanguage, LanguageCard, AddFacilitiesModal } from "../Components"
import { Dropdown } from '@/core/components'

export default {
  name: 'ProjectPage',
  components: {
    AddNewLanguage,
    LanguageCard,
    Dropdown,
    AddFacilitiesModal,
  },
  setup(){
    const router = useRouter();
    const route = useRoute();
    const facilityStore = useFacilityStore();
    const isSuccessImport = ref(false)

    const project = ref({});
    const projectId = ref(route.params.id);
    const defaultLang = ref({});
    const applicationRole = ref(null);
    const userRole = ref(null);
    const facilitySelected = ref(null);
    const facilityList = ref(null);
    const facilityDefaultForDropdown = ref(null);
    const nationalFacility = ref(null);
    const defaultFacility = ref(null);

    const addFacilitiesModalRef = ref(null);
    const showSuccessMessageFacility = ref(false);
    const showFailedMessageFacility = ref(false);
    const errorMessageFacility = ref(false);

    const isSuperAdmin = computed(() => {
      return userRole.value === Constants.USER_ROLES.SUPER_ADMINISTRATOR
    })

    const isAdmin = computed(() => {
      return project.value.application_role === Constants.USER_ROLES.ADMINISTRATOR;
    })

    const isLanguageEditor = computed(() => {
      return project.value.application_role === Constants.USER_ROLES.LANGUAGE_EDITOR;
    })

    const isMaster = computed(() => {
      return project.value.is_master;
    })

    const onSuccessImport = () => {
      isSuccessImport.value = true

      setTimeout(()=> {
        isSuccessImport.value = false
      }, 1500)
    }

    const languageCardConditional = (languageId) => {
      if (isLanguageEditor.value && facilitySelected.value && facilitySelected.value.languages) {
        return facilitySelected.value.languages.includes(languageId);
      }
      return true
    }

    const dropdownConditionals = computed(() => {
      return isSuperAdmin.value || isMaster.value || (!isMaster.value && facilityList.value.length > 1);
    })

    const isNotNationalFacilityToAdminRole = computed(() => {
      if (isAdmin.value && isMaster.value) {
        const facility = JSON.stringify(facilitySelected.value);
        const national = JSON.stringify(nationalFacility.value);
        return facility !== national;
      }
      return false
    })

    const setInitialValues = (response) => {
      project.value = response;
      facilityList.value = response.facilities_list;
      applicationRole.value = response.application_role;
      nationalFacility.value = response.national_facility;
      defaultFacility.value = response.default_facility;

      facilityDefaultForDropdown.value = facilityStore.getFacility ?? defaultFacility.value;
      facilitySelected.value = {...facilityDefaultForDropdown.value};
    }

    const getProject = async () => {
      try {
        Spinner.start();
        const m_facility_id = facilityStore.getFacility ? facilityStore.getFacility.id : undefined;
        const response = await ProjectServices.getProject(projectId.value, m_facility_id);
        setInitialValues(response);
      } catch (e) {
        console.error(e)
        goToDashboardPage();
      } finally {
        Spinner.stop()
      }
    };

    const defaultLanguage = computed(() => {
      if(project.value && project.value.languages && !isLanguageEditor.value){
        const language = project.value.languages.find((lang) => {
          return lang.culture_name === project.value.default_language;
        });
        return language ?? {}
      }
      return {}
    });

    const projectLanguages = computed(() => {
      return project.value ? project.value.languages : []
    });

    const addNewLanguage = (language) => {
      project.value.languages.push(language);
    };

    const goToDashboardPage = () => {
      router.push({
        name: 'dashboard-page'
      })
    };

    const onDeleteLanguage = async () => {
      await getProject();
    }

    const handleOpenFacilitiesModal = () => {
      showSuccessMessageFacility.value = false;
      showFailedMessageFacility.value = false;
      addFacilitiesModalRef.value.showModal();
    }

    const handleSaveFacility = async (facilityName) => {
      try {
        Spinner.start();
        const data = {name: facilityName};
        facilityList.value = await ProjectServices.updateFacilities(projectId.value, data);
        showSuccessMessageFacility.value = true;
      } catch (e) {
        errorMessageFacility.value = e;
        showFailedMessageFacility.value = true;
      } finally {
        addFacilitiesModalRef.value.hideModal();
        Spinner.stop();
      }
    }

    const handleSelectOption = async (facility) => {
      facilitySelected.value = facility;
      handleSetFacility(facilitySelected.value);
      await getProject();
    }

    const handleSetFacility = (facility) => {
      facilityStore.setFacility(facility);
    }

    const handleGoToAdministratorRoles = () => {
      router.push({
        name: 'admin-role-page',
        params: {
          id: projectId.value,
          facilityId: facilitySelected.value.id,
        }
      })
    }

    onMounted(async () => {
      await getProject()
      const accessToken = Storage.getAccessToken();
      if (accessToken) {
        const user = Storage.getUser();
        userRole.value = user.role_code;
      }
    })

    return {
      project,
      isLanguageEditor,
      isSuperAdmin,
      isAdmin,
      isMaster,
      isNotNationalFacilityToAdminRole,
      dropdownConditionals,
      defaultLang,
      addNewLanguage,
      defaultLanguage,
      projectLanguages,
      onDeleteLanguage,
      facilitySelected,
      facilityList,
      facilityDefaultForDropdown,
      nationalFacility,
      addFacilitiesModalRef,
      handleOpenFacilitiesModal,
      handleSaveFacility,
      showSuccessMessageFacility,
      showFailedMessageFacility,
      errorMessageFacility,
      handleSelectOption,
      handleSetFacility,
      handleGoToAdministratorRoles,
      languageCardConditional,
      onSuccessImport,
      isSuccessImport
    }
  }
}
</script>

<style scoped lang="stylus">
@import "../Styles/project_page.styl";
</style>
