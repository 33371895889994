import { defineStore } from "pinia";

export const useFacilityStore = defineStore({
  id: 'facility',
  state: () => ({
    facility: null,
  }),
  getters: {
    getFacility: (state) => {
      return state.facility;
    }
  },
  actions: {
    setFacility(facility) {
      this.facility = facility;
    }
  },
})
