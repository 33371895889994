<template>
  <Modal ref="modalRef" :modal-title="title">
    <template v-slot:modalContent>
      <p>{{ message }}</p>

      <div class="flex justify-end items-center container-modal-btns">
        <button @click="hideModal" class="common-btn common-btn--secondary mr-3">Cancel</button>
        <button class="common-btn" @click="onOkClick">Ok</button>
      </div>
    </template>
  </Modal>
</template>

<script>

  import { ref } from 'vue'
  import { Modal } from '@/core/components'

  export default {
    name: 'DeleteTerms',
    components: { Modal },
    props: ["title", "message"],
    setup(props, { emit }) {

      const modalRef = ref(null);

      const showModal = () => {
        modalRef.value.showModal();
      };

      const hideModal = () => {
        modalRef.value.hideModal();
      };

      const onOkClick = () => {
        emit('onOkClick');
      }

      return {
        modalRef,
        showModal,
        hideModal,
        onOkClick
      }
    }
  }
</script>

<style scoped lang="stylus">
</style>
