<template>
    <div class="bg-white rounded-xl p-4 md:p-7 flex flex-col justify-between project-card is-add-card">
      <div class="flex justify-center">
        <div class="icon-add-project"></div>
      </div>
      <div class="flex justify-center">
        <button class="common-btn btn-add-project" @click="toggleForm(true)">Add new project</button>
      </div>

      <AddEditProjectModal
        ref="addProjectModalRef"
        :is-edit="false"
        @onAddNewProject="onAddNewProject"/>
    </div>
</template>

<script>

  import { ref } from 'vue'
  import AddEditProjectModal from "./AddEditProjectModal.vue"

  export default {
    name: 'AddNewProject',
    components: { AddEditProjectModal },
    setup(props, { emit }) {

      const addProjectModalRef = ref(null);

      const toggleForm = (show) => {
        if(show === true){
          addProjectModalRef.value.showModal();
        } else {
          addProjectModalRef.value.hideModal();
        }
      };

      const onAddNewProject = (newProject) => {
        emit("onAddNewProject", newProject);
      };

      return {
        toggleForm,
        onAddNewProject,
        addProjectModalRef
      }
    }
  }
</script>

<style scoped lang="stylus">
</style>
