function getUser() {
  const user = localStorage.getItem('user') || '{}'
  return JSON.parse(user)
}

function setUser(user) {
  localStorage.setItem('user', JSON.stringify(user))
}

function clearUser() {
  localStorage.removeItem('user')
}

function setSsoState(ssoState) {
    localStorage.setItem('ssoState', ssoState);
}

function getSsoState() {
    return localStorage.getItem('ssoState');
}

function clearSsoState() {
  localStorage.removeItem('ssoState')
}

function setAccessToken(accessToken) {
  localStorage.setItem('accessToken', accessToken);
}

function getAccessToken() {
  return localStorage.getItem('accessToken');
}

function clearAccessToken() {
  localStorage.removeItem('accessToken')
}

function clearAll() {
  localStorage.clear();
}

export default {
  getUser,
  setUser,
  clearUser,
  setSsoState,
  getSsoState,
  clearSsoState,
  setAccessToken,
  getAccessToken,
  clearAccessToken,
  clearAll
}
