<template>
  <div class="wrapper-add-term-form mb-4">
    <div class="container mx-auto">
      <form @submit.prevent="onSubmit">
        <div class="card-term-form p-5 pb-6 bg-white">
          <div class="md:flex items-align">
            <div class="common-form-group md:mr-5 md:max-w-360">
              <label for="key_term" class="common-label-input">New key term*</label>
              <textarea id="key_term" class="common-input is-textarea add-term-form-textarea" v-model="keyTerm"></textarea>
            </div>
            <div class="common-form-group md:mr-2">
              <label class="common-label-input" :class="labelClass" for="file_input_audio">Upload Audio*</label>
              <input class="mb-1" :class="inputClass" @change="setAudioFile" ref="audioRef"  id="file_input_audio" type="file" accept="audio/mpeg">
              <label class="common-label-input" :class="labelClass" for="file_input_subtitle">Upload Subtitles</label>
              <input :class="inputClass" @change="setSubtitleFile" ref="subtitleRef" id="file_input_subtitle" type="file" accept="text/vtt, text/plain">
            </div>
            <button type="submit" class="common-btn btn-add-term">Add</button>
          </div>
          <div v-if="errorMessage" class="common-error-message mt-3">Key is required</div>
          <div v-if="errorAudioMessage" class="common-error-message mt-3">Audio File is required</div>
          <div v-if="errorAudioMessageFromService" class="common-error-message mt-3">{{ errorAudioMessageFromService }}</div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { Spinner, Utils } from "@/core/utils"
import { ProjectServices } from "../Services"


export default {
  name: "AddAudioFile",
  props: {
    projectId: {
      type: [String, Number],
      default: "",
      required: true
    },
    languageId: {
      type: [String, Number],
      default: "",
      required: true
    },
    facilityId: {
      type: [String, Number],
      default: "",
      required: true
    }
  },
  setup(props,{emit}) {
    const keyTerm = ref(null)
    const audioRef = ref(null)
    const audio = ref(null)
    const subtitleRef = ref(null)
    const subtitle = ref(null)
    const errorMessage = ref(null)
    const errorAudioMessage = ref(null)
    const errorAudioMessageFromService = ref(null)

    const labelClass = computed(() => {
      return 'block text-sm font-medium text-gray-900 dark:text-gray-300';
    })

    const inputClass = computed(() => {
      return 'block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400';
    })
    
    const setAudioFile = () => {
      audio.value = audioRef.value.files[0]
    }

    const setSubtitleFile = () => {
      subtitle.value = subtitleRef.value.files[0]
    }

    const resetForm = () => {
      keyTerm.value = null;
      audio.value = null;
      audioRef.value.value = null;
      subtitle.value = null;
      subtitleRef.value.value = null;
    };

    const resetErrorMessages = () => {
      errorMessage.value = false
      errorAudioMessage.value = false
      errorAudioMessageFromService.value = null
    }

    const onSubmit = async () => {
      try {
        Spinner.start()
        resetErrorMessages()
        const data = {
          term: keyTerm.value,
          audio_file: audio.value,
          subtitle_file: subtitle.value
        }

        if(keyTerm.value === null) {
          errorMessage.value = true
        } else {
          if(audio.value === null && subtitle.value !== null) {
            errorAudioMessage.value = true
          } else {
            const formData = Utils.getFormData(data)
            await ProjectServices.addUpdateTranslationWithAudio(props.projectId, props.languageId, props.facilityId, formData)
            emit('getTranslations')
            resetForm()
          }
        }
      } catch (error) {
        console.error(error)
        errorAudioMessageFromService.value = error
      } finally {
        Spinner.stop()
      }
    }
    return {
      errorMessage,
      errorAudioMessage,
      errorAudioMessageFromService,
      labelClass,
      inputClass,
      keyTerm,
      audioRef,
      audio,
      subtitleRef,
      subtitle,
      setAudioFile,
      setSubtitleFile,
      onSubmit
    }
  }
}
</script>

<style scoped lang="stylus">
@import "../Styles/add_term_form.styl";
</style>