import {SSO, Storage} from '../../../core/utils'
import {http} from '@/http'

const resource = "/api/admin/auth/";

export default {
    redirectToAuthorizeURL() {
        const ssoState = SSO.getRandomState();
        Storage.setSsoState(ssoState);
        const params = {
            client_id: process.env.VUE_APP_SSO_AUTH_CLIENT_ID,
            redirect_uri: process.env.VUE_APP_SSO_AUTH_REDIRECT_URI,
            response_type: process.env.VUE_APP_SSO_AUTH_RESPONSE_TYPE,
            scope: process.env.VUE_APP_SSO_AUTH_SCOPE,
            state: ssoState,
            nonce: '2',
        };
        const esc = encodeURIComponent;
        const query = Object.keys(params).map(k => esc(k) + '=' + esc(params[k])).join('&');
        location.href = [process.env.VUE_APP_SSO_AUTH_ENDPOINT, query].join('?');

    },
    loginSSO(payload) {
        return http.post(resource, payload)
    },
    logout() {
        Storage.clearAll()
    },
}
