<template>
  <div class="relative custom-select-container">
    <div class="common-label-input" v-if="label">{{ label }}</div>

    <vue-select
      v-model="selectedOpt"
      :options="options"
      searchable
      clear-on-close
      close-on-select
      label-by="text"
      :placeholder="placeholder"
      :search-placeholder="placeholder"
      @selected="selectOption"
    />

  </div>
</template>

<script>

  export default {
    name: 'CustomSelect',
    props: {
      label: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default: () => {
          return []
        }
      },
      defaultVal: {
        required: false
      }
    },
    data() {
      return {
        selectedOpt: null
      }
    },
    methods:{
      selectOption(opt) {
        this.$emit("onSelectOption", opt)
      }
    },
    beforeMount() {
      if(this.defaultVal){
        this.selectedOpt = this.options.find((opt) => opt.value == this.defaultVal)
      }
    }
  }
</script>

<style scoped lang="stylus">
  @import "styles/custom-select.styl";
</style>
