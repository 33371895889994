import {http} from '@/http'

const resource = "/api/admin";

export default {
  export(projectId, languageId, facilityId) {
    return http.get(`${resource}/application/${projectId}/language/${languageId}/facility/${facilityId}/export`, { responseType: 'blob' });
  },
  import(projectId, languageId, facilityId, payload) {
    return http.post(`${resource}/application/${projectId}/language/${languageId}/facility/${facilityId}/import`, payload);
  },
  getTranslations(projectId, languageId, facilityId) {
    return http.get(`${resource}/translation/?application_id=${projectId}&language_id=${languageId}&facility_id=${facilityId}`);
  },
  getTranslationsWithAudio(projectId, languageId, facilityId) {
    return http.get(`${resource}/translation/audio/?application_id=${projectId}&language_id=${languageId}&facility_id=${facilityId}`);
  },
  getLanguages() {
    return http.get(`${resource}/language/`);
  },
  addUpdateTranslation(projectId, languageId, facilityId, payload) {
    return http.post(`${resource}/translation/?application_id=${projectId}&language_id=${languageId}&facility_id=${facilityId}`, payload);
  },
  addUpdateTranslationWithAudio(projectId, languageId, facilityId, formData) {
    return http.post(`${resource}/translation/audio/?application_id=${projectId}&language_id=${languageId}&facility_id=${facilityId}`, formData);
  },
  getProject(projectId, facilityId) {
    const query_string = facilityId ? `?facility_id=${facilityId}` : '';
    return http.get(`${resource}/application/${projectId}/${query_string}`);
  },
  deleteTerm(projectId) {
    return http.delete(`${resource}/translation/?term_id=${projectId}`);
  },
  addNewLanguage(projectId, languageId) {
    return http.put(`${resource}/application/${projectId}/language/${languageId}`);
  },
  deleteTerms(termsIds, facilityId) {
    return http.patch(`${resource}/term/?facility_id=${facilityId}`, termsIds);
  },
  deleteTermsWithAudio(termsIds) {
    return http.patch(`${resource}/term/`, termsIds);
  },
  clearTranslations(translationIds, facilityId) {
    return http.patch(`${resource}/translation/?facility_id=${facilityId}`, translationIds);
  },
  clearTranslationsWithAudio(translationIds, facilityId) {
    return http.patch(`${resource}/translation/audio/?facility_id=${facilityId}`, translationIds);
  },
  deleteLanguage(projectId, facilityId, languageId) {
    return http.delete(`${resource}/application/${projectId}/language/${languageId}/?facility_id=${facilityId}`);
  },
  getAdministratorUsers(projectId, facilityId) {
    return http.get(`${resource}/application/${projectId}/admins/?facility_id=${facilityId}`)
  },
  addAdministratorUser(projectId, facilityId, data) {
    return http.put(`${resource}/application/${projectId}/admins/?facility_id=${facilityId}`, data);
  },
  deleteAdministratorUser(projectId, facilityId, userId) {
    return http.delete(`${resource}/application/${projectId}/admins/${userId}/?facility_id=${facilityId}`)
  },
  getLangEditorUsers(projectId, facilityId, languageId) {
    return http.get(`${resource}/application/${projectId}/editors/${languageId}/?facility_id=${facilityId}`)
  },
  addLangEditorUsers(projectId, languageId, facilityId, data) {
    return http.put(`${resource}/application/${projectId}/editors/${languageId}/?facility_id=${facilityId}`, data)
  },
  deleteLangEditorUser(projectId, languageId, facilityId, userId) {
    return http.delete(`${resource}/application/${projectId}/editors/${languageId}/${userId}/?facility_id=${facilityId}`)
  },
  updateFacilities(projectId, data) {
    return http.put(`${resource}/application/${projectId}/facilities`, data)
  }
}
