<template>
  <div class="wrapper-breadcrumb py-5">
    <div class="container flex items-align justify-between mx-auto">
      <router-link :to="{name: 'language-page'}" class="breadcrumb-link">
        {{ project ? project.name + ' / ' + getCurrentLanguage.culture_display_name  + ' / Manage Language Editors' : 'Go to Dashboard' }}
      </router-link>
    </div>
  </div>
  <roles-manager
    is-language-editor
    :project-id="projectId"
    :language-id="languageId"
    :facility-id="facilityId"
  />
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {RolesManager} from "../Components";
import {computed, ref} from "vue";
import {Spinner} from "@/core/utils";
import {ProjectServices} from "@/modules/Project/Services";

export default {
  name: "ProjectLangEditorRolePage",
  components: {
    RolesManager,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const project = ref({});
    const projectId = ref(route.params.id);
    const languageId = ref(route.params.languageId)
    const facilityId = ref(route.params.facilityId)
    const userId = ref(null)
    const errorMessageFromService = ref(null)
    const langEditorUsers = ref([])

    const inputEmailClass = computed(() => {
      return 'mr-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
    })

    const buttonAddClass = computed(() => {
      return 'self-end w-1/4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
    })

    const buttonDeleteClass = computed(() => {
      return 'w-1/8 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
    })

    const listUsersClass = computed(() => {
      return 'm-auto w-4/6 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white'
    })

    const getProject = async () => {
      try {
        Spinner.start();
        project.value = await ProjectServices.getProject(projectId.value, facilityId.value)
        langEditorUsers.value = await ProjectServices.getLangEditorUsers(projectId.value, facilityId.value, languageId.value);
      } catch (e) {
        console.error(e)
        goToDashboardPage()
      } finally {
        Spinner.stop();
      }
    };

    const getCurrentLanguage = computed(() => {
      let language = {}
      if(project.value && project.value.languages) {
        language = project.value.languages.find((lang) => {
          return parseInt(lang.id) === parseInt(languageId.value)
        })
      }
      return language.id ? language : {}
    })

    const goToDashboardPage = () => {
      router.push({
        name: 'dashboard-page'
      })
    };

    const onSubmit = async () =>  {
      try {
        Spinner.start()
        const data = {user_id: userId.value}
        await ProjectServices.addAdministratorUser(projectId.value, data)
        userId.value = null
      } catch (e) {
        console.error(e)
        errorMessageFromService.value = e
      } finally {
        Spinner.stop()
      }
    }

    getProject()

    return {
      projectId,
      languageId,
      facilityId,
      inputEmailClass,
      buttonAddClass,
      buttonDeleteClass,
      listUsersClass,
      project,
      langEditorUsers,
      getCurrentLanguage,
      onSubmit,
      userId
    }
  }
}
</script>

<style scoped lang="stylus">
@import "../Styles/project_page.styl";
</style>
