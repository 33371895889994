<template>
    <div>
        <div class="py-32">
            <div class="flex items-center justify-center">
                <div class="bg-white rounded-xl  justify-between p-32 py-20 mx-2">
                    <div class="flex flex-col items-center md:w-auto">
                        <h2 class="login__title ">
                            LOGIN
                        </h2>
                        <button class="common-btn" @click="login">LOG IN WITH OKTA</button>
                    </div>
                </div>

            </div>
        </div>


    </div>
</template>

<script>
    import { useAuth } from '@okta/okta-vue';

    export default {
        name: 'Login',
        setup() {
            const login = async () => {
              const $auth = useAuth();
              await $auth.signInWithRedirect();
            };

            return {
              login
            };
        }
    }
</script>

<style lang="stylus" scoped>
    @import "../Styles/login.styl";
</style>
