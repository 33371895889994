<template>
  <div v-if="show" :class="{'scale-100': show}"
       class="fixed top-0 left-0 w-screen h-screen flex items-center
       justify-center transform scale-0 transition-transform
       duration-300 z-50 bg-black bg-opacity-30 custom-modal" v-bind="$attrs" >

    <div class="bg-white custom-modal__wrapper-content">
      <div class="flex justify-end">
        <button @click="hideModal" class="button-close-modal"></button>
      </div>
      <h2 class="modal-title" v-if="modalTitle">{{ modalTitle }}</h2>

      <slot name="modalContent"></slot>
    </div>
  </div>
</template>

<script>

  import { ref } from 'vue'

  export default {
    name: 'Modal',
    props: {
      modalTitle: {
        type: String,
        default: ''
      }
    },
    setup(props, { emit }) {

      const show = ref(false);
      const showModal = () => {
        show.value = true;
      };

      const hideModal = () => {
        show.value = false;
        emit('onHideModal');
      }

      return {
        show,
        showModal,
        hideModal
      }
    }
  }
</script>

<style scoped lang="stylus">
  @import "modal.styl";
</style>
