<template>
  <div class="wrapper-language-page">
    <div class="wrapper-breadcrumb py-5">
      <div class="container flex flex-start flex-col items-center md:flex-row flex-wrap md:justify-between items-align mx-auto">
        <div class="md:w-2/3">
          <router-link v-if="project" :to="{name: 'project-page', params: {id: project.id}}" class="breadcrumb-link">
            {{ project.name }} / {{ getCurrentLanguage.culture_display_name }}
          </router-link>
          <a v-else href="/" class="breadcrumb-link"></a>
        </div>
        <div class="flex flex-col md:justify-end items-center" v-if="!isDefaultLanguage">
          <div class="flex mb-2">
            <div class="mr-4 text-count-terms"><strong>{{ filteredTranslations ? filteredTranslations.length : 0 }}</strong> Terms</div>
            <div class="text-count-terms"><strong>{{ getCountTranslations }}</strong> Translations</div>
          </div>
          <router-link v-if="!isLanguageEditor" :to="{name: 'lang-editor-role-page'}" class="manage-list">Language Editor Roles</router-link>
        </div>
      </div>
    </div>
    <div class="container flex justify-between mx-auto mt-4">
      <div
        v-for="item in fileTypes"
        :key="item.id"
        class="select-file-block p-7 text-center"
        :style="setWidthFileType" :class="item.value === itemSelected ? 'selected' : null"
        @click="handleSelectFileType(item.value)"
      >
        {{item.value}}
      </div>
    </div>

    <div v-if="isKeyTerms" class="wrapper-inner-page py-10 pb-15">
      <div v-if="showForm" :class="{'hidden': !showForm}">
        <add-term-form
          :project-id="projectId"
          :language-id="languageId"
          :facility-id="facilityId"
          @addTranslation="addTranslation"
        />
      </div>

      <translation-list
        :translations="filteredTranslations"
        :is-default-lang="isDefaultLanguage"
        @updateTranslation="updateTranslation"
        @onDeleteTerms="handleOnDeleteTerms"
        @onFiltersChange="handleFiltersChange"
        :default-order="defaultOrder"
      />
    </div>
    <div v-if="isAudioFiles" class="wrapper-inner-page py-10 pb-15">
      <div v-if="showForm" :class="{'hidden': !showForm}">
        <add-audio-file
          :project-id="projectId"
          :language-id="languageId"
          :facility-id="facilityId"
          @getTranslations="getTranslationsWithAudio"
        />
      </div>
      <translation-list
        :translations="filteredTranslations"
        :is-default-lang="isDefaultLanguage"
        @updateTranslation="updateTranslation"
        @onDeleteTerms="handleOnDeleteTerms"
        @onDeleteTermsWithAudio="handleDeleteTermsWithAudio"
        @onFiltersChange="handleFiltersChange"
        :default-order="defaultOrder"
        is-audio-list
      />
    </div>
  </div>
</template>

<script>
import {ref, computed, watch, onMounted, provide} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Spinner, Constants } from "@/core/utils"
import { ProjectServices } from "../Services"
import { AddTermForm, TranslationList } from "../Components"
import AddAudioFile from "@/modules/Project/Components/AddAudioFile"

export default {
  name: 'ProjectLanguagePage',
  components: {
    AddAudioFile,
    AddTermForm,
    TranslationList
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const projectId = ref(route.params.id)
    const languageId = ref(route.params.languageId)
    const facilityId = ref(route.params.facilityId)

    provide('projectId', projectId.value);
    provide('languageId', languageId.value);
    provide('facilityId', facilityId.value);

    const project = ref({})

    const defaultOrder = ref('asc')

    const itemSelected = ref(Constants.FILES.KEY_TERMS)
    const translations = ref([])

    const filters = ref({
      orderBy: 'asc',
      search: ''
    })

    const isKeyTerms = computed(() => {
      return itemSelected.value === Constants.FILES.KEY_TERMS
    })

    const isAudioFiles = computed(() => {
      return itemSelected.value === Constants.FILES.AUDIO_FILES
    })

    const fileTypes = computed(() => {
      return Constants.FILE_TYPE
    })

    const isLanguageEditor = computed(() => {
      return project.value.application_role === Constants.USER_ROLES.LANGUAGE_EDITOR
    })

    onMounted(() => {
      //ToDo: add reactive facility value
      getTranslations(true)
    })

    const getProject = async () => {
      project.value = await ProjectServices.getProject(projectId.value, facilityId.value)
    }

    const getDefaultLanguage = computed(() => {
      let language = {}
      if (project.value && project.value.languages) {
        language = project.value.languages.find((lang) => {
            return lang.culture_name === project.value.default_language
        })
      }
      if (language) {
        return language.id ? language : {}
      } else {
        return {}
      }
    })

    const getCurrentLanguage = computed(() => {
      let language = {}
      if(project.value && project.value.languages) {
        language = project.value.languages.find((lang) => {
            return parseInt(lang.id) === parseInt(languageId.value)
        })
      }
      return language ?? {}
    })

    const isDefaultLanguage = computed(() => {
      const defaultLang = getDefaultLanguage.value
      const currentLang = getCurrentLanguage.value
      if (defaultLang && currentLang) return defaultLang.id === currentLang.id;
      return false;
    })

    const getTranslations = async (isBeingMounted = false) => {
      try {
        Spinner.start()
        if (isBeingMounted) await getProject()
        translations.value = await ProjectServices.getTranslations(projectId.value, languageId.value, facilityId.value)
      } catch (e) {
        console.error(e)
        await router.push({
          name: 'dashboard-page'
        })
      } finally {
        Spinner.stop()
      }
    }

    const getTranslationsWithAudio = async () => {
      try {
        Spinner.start()
        translations.value  = await ProjectServices.getTranslationsWithAudio(projectId.value, languageId.value, facilityId.value)
      } catch (e) {
        console.error(e)
      } finally {
        Spinner.stop()
      }
    }

    const addTranslation = (translation) => {
      translations.value.push(translation)
    }

    const filteredTranslations = computed(() => {
      let filteredList = translations.value

      if (filters.value.search) {
        filteredList = translations.value.filter((item) => {
          const key = item.key ? item.key.toLowerCase() : ""
          const value = item.value ? item.value.toLowerCase() : ""
          const defaultValue = item.default_value ? item.default_value.toLowerCase() : ""
          const audio_url = item.audio_url ?? ""
          const audio_name = item.audio_name ?? ""

          const searchValue = filters.value.search.toLowerCase()

          return key.includes(searchValue) || value.includes(searchValue) || defaultValue.includes(searchValue) || audio_url.includes(searchValue) || audio_name.includes(searchValue)
        })
      }

      return filteredList.sort(function (a, b) {
        if(isDefaultLanguage.value || !a.default_value){
          return filters.value.orderBy === 'asc' ? a.key.localeCompare(b.key) : b.key.localeCompare(a.key)
        } else if (a.default_value) {
          return filters.value.orderBy === 'asc' ? a.default_value.localeCompare(b.default_value) : b.default_value.localeCompare(a.default_value)
        }
      })
    })

    const showForm = computed(() => {
      return isDefaultLanguage.value === true
    })

    const updateTranslation = (translation) => {
      translations.value = translations.value.map((item) => {
        if (item.id === translation.id) {
          return translation
        } else if (item.term_id === translation.term_id) {
          return translation
        }
        return item
      })
      if (itemSelected.value === Constants.FILES.KEY_TERMS) getTranslations(false)
      if (itemSelected.value === Constants.FILES.AUDIO_FILES) getTranslationsWithAudio()
    }

    const handleOnDeleteTerms = () => {
      getTranslations(false)
    }

    const handleDeleteTermsWithAudio = () => {
      getTranslationsWithAudio()
    }

    const handleFiltersChange = (newFilters) => {
      filters.value.orderBy = newFilters.orderBy
      filters.value.search = newFilters.search
    }

    const getCountTranslations = computed(() => {
      let translationsList = [...filteredTranslations.value]
      translationsList = translationsList.filter((item) => {
        return item.value
      })

      return translationsList ? translationsList.length : 0
    })

    const setWidthFileType = computed(() => {
      return { width : (100/fileTypes.value.length)-1 + '%' }
    })

    const handleSelectFileType = (value) => {
      itemSelected.value = value
    }



    watch(
      () => itemSelected.value,
      () => {
        switch (itemSelected.value) {
          case Constants.FILES.KEY_TERMS:
            getTranslations(false);
            break;

          case Constants.FILES.AUDIO_FILES:
            getTranslationsWithAudio();
            break;
        }
      }
    )

    return {
      isKeyTerms,
      isAudioFiles,
      fileTypes,
      filteredTranslations,
      projectId,
      languageId,
      facilityId,
      project,
      isLanguageEditor,
      addTranslation,
      getCurrentLanguage,
      updateTranslation,
      isDefaultLanguage,
      showForm,
      handleOnDeleteTerms,
      handleDeleteTermsWithAudio,
      defaultOrder,
      handleFiltersChange,
      getCountTranslations,
      itemSelected,
      setWidthFileType,
      handleSelectFileType,
      getTranslationsWithAudio
    }
  }
}
</script>

<style scoped lang="stylus">
@import "../Styles/language_card.styl"
  .text-count-terms {
    color: #ffffff
    font-size: 18px
  }

.manage-list
  cursor pointer
  display inline-block
  font font-opensans-regular
  font-size 16px
  color colors.gray-5;
  padding 2px 15px
  position relative
  max-width 100%
  min-height 20px
  border 1px solid colors.gray-5
  border-radius 15px
  &:hover
    color colors.gray-3
    border-color color colors.gray-3
</style>
