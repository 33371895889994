<template>
  <div class="wrapper-translation-block">
    <div class="container mx-auto">
      <div class="translation-block bg-white">
        <div class="container-filters p-5 pb-8">
          <div class="flex md:justify-between md:items-center flex-col md:flex-row">
            <div class="flex md:items-center flex-col md:flex-row">
              <div class="md:mr-4 mb-4 md:mb-0">
                <dropdown
                  label="Order by"
                  :options="orderByOptions"
                  :default-val="defaultOrder"
                  @onSelectOption="filterRows"
                />
              </div>
              <div class="mb-4 md:mb-0 pt-5" v-if="translations.length > 0">
                <button class="common-link inline-block mr-4" @click="selectAll(true)"
                        v-if="idsToDelete.length < translations.length">Select All</button>
                <button class="common-link inline-block mr-4" @click="selectAll(false)" v-else>Unselect All</button>
                <button type="button"
                        class="common-link inline-block"
                        @click="showDeleteModal"
                        v-if="idsToDelete.length > 0">

                  <span v-if="isDefaultLang">Delete</span>
                  <span v-else>Clear selected</span>
                </button>
              </div>
            </div>
            <div class="common-form-group md:ml-5 md:max-w-294">
              <label for="search" class="common-label-input">Search</label>
              <input type="text" id="search" class="common-input" v-model="searchText" @change="searchByText">
            </div>
          </div>
          <div class="common-error-message mt-8" v-if="errorMessage">{{ errorMessage }}</div>
        </div>

        <div class="container-translation-list">
          <div v-if="translations.length > 0">
            <translation-row
              v-for="translation in translations"
              :key="`translation-row-term-id-${translation.term_id}`"
              :translation="translation"
              @updateTranslation="updateTranslation"
              @onSelectCheckbox="onSelectCheckbox"
              :is-default-lang="isDefaultLang"
            />
          </div>
          <div v-else>
            <div class="translation-row p-5">
              <span>No translations</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <delete-terms
      ref="modalDeleteRef"
      @onOkClick="submitDelete()"
      :title="`Are you sure you want to ${isDefaultLang ? 'delete all fields?' : 'clear all fields?'}`"
      :message="isDefaultLang ? 'Please keep in mind that the terms and all of their translations for every language will be lost.' : 'Every translation entered for this language will be lost.'"
    />

  </div>
</template>

<script>

import {ref, computed, provide, inject} from 'vue'

  import { Dropdown } from '@/core/components'
  import { Spinner } from "@/core/utils"
  import { ProjectServices } from "../Services"
  import DeleteTerms from "./DeleteTerms.vue"
  import TranslationRow from "./TranslationRow.vue";

  const ORDER_BY_OPTS = [
    {
      value: 'asc',
      text: 'Ascending'
    },
    {
      value: 'desc',
      text: 'Descending'
    }
  ];

  export default {
    name: 'TranslationList',
    components: {TranslationRow, Dropdown, DeleteTerms },
    props: {
      translations: {
        type: Array,
        default: () => {
          return []
        }
      },
      isDefaultLang: {
        type: Boolean,
        default: false
      },
      defaultOrder: {
        type: String,
        default: 'asc'
      },
      isAudioList: {
        type: Boolean,
        default: false
      }
    },
    setup( props, { emit } ) {
      const idsToDelete = ref([]);
      const errorMessage = ref("");
      const orderByOptions = ref(ORDER_BY_OPTS);
      const selectedOrderBy = ref(props.defaultOrder);
      const searchText = ref("");

      const modalDeleteRef = ref(null);

      provide('isAudioList', props.isAudioList);

      const facilityId = inject('facilityId');

      const updateTranslation = (translation) => {
        emit('updateTranslation', translation);
      };

      const onSelectCheckbox = (translation) => {
        if (translation.term_id) verifyItem(translation.term_id);
      };

      const addIdToDelete = (termId) => {
        idsToDelete.value.push(termId);
      };

      const removeIdToDelete = (idIndex) => {
        idsToDelete.value.splice(idIndex, 1);
      };

      const verifyItem = (termId) => {
        const itemIndex = idsToDelete.value.findIndex((item) => item == termId);

        if (itemIndex === -1) addIdToDelete(termId)
        else removeIdToDelete(itemIndex);
      };

      const getIdsToDelete = computed(() => {
        let ids = []
        if(!idsToDelete.value || (idsToDelete.value && idsToDelete.value.length == 0)){
          ids = [];
        } else {
          idsToDelete.value.map((termId) => {
            const translation = props.translations.find(translation => translation.id && translation.term_id == termId)
            if(translation){
              ids.push(translation.id);
            }
          });
        }
        return ids;
      });

      const deleteTerms = async () => {
        try {
          Spinner.start();
          if (idsToDelete.value.length > 0) {
            await ProjectServices.deleteTerms(idsToDelete.value, facilityId);
            idsToDelete.value = [];
            if (!props.isAudioList) {
              emit('onDeleteTerms');
            } else {
              emit('onDeleteTermsWithAudio');
            }
          }
        } catch (e) {
          errorMessage.value = e;
          setTimeout(() => {
            errorMessage.value = "";
          }, 3000);
        } finally {
          hideDeleteModal();
          Spinner.stop();
        }
      };

      const clearTranslations = async () => {
        try {
          if(idsToDelete.value.length > 0) {
            Spinner.start();
            if(getIdsToDelete.value.length > 0) {
              if(!props.isAudioList) {
                await ProjectServices.clearTranslations(getIdsToDelete.value, facilityId);
                idsToDelete.value = [];
                emit('onDeleteTerms');
                hideDeleteModal();
                selectAll(false)
              } else {
                await ProjectServices.clearTranslationsWithAudio(getIdsToDelete.value, facilityId);
                idsToDelete.value = [];
                emit('onDeleteTermsWithAudio');
                hideDeleteModal();
                selectAll(false)
              }
            }
          } else {
            idsToDelete.value = [];
              emit('onDeleteTerms');
            }
        } catch (error) {
          errorMessage.value = error;
          errorMessage.value = "Unexpected error, please try again";
          hideDeleteModal();
          setTimeout(() => {
            errorMessage.value = "";
          }, 3000);
        } finally {
          Spinner.stop();
        }
      };


      const submitDelete = () => {
        if( props.isDefaultLang === true ){
          deleteTerms();
        } else {
          clearTranslations();
        }
      };

      const searchByText = () => {
        filterRows();

        const ids = [];

        setTimeout(() => {
          props.translations.map((itemT) => {
            const id = idsToDelete.value.find((itemId) => itemT.term_id == itemId);
            if(id){
              ids.push(id);
            }
          });

          idsToDelete.value = ids;
        }, 0);

      };

      const filterRows = (opt) => {
        selectedOrderBy.value = opt ? opt.value : selectedOrderBy.value;

        const filters = {
          orderBy: selectedOrderBy.value,
          search: searchText.value
        };

        emit('onFiltersChange', filters);
      };

      const selectAll = (select_all) => {
        idsToDelete.value = [];

        props.translations.map((item) => {
          const checkbox = document.getElementById(`checkbox_term_${item.term_id}`);
          if(checkbox) checkbox.checked = select_all;
          if(select_all && item.term_id) addIdToDelete(item.term_id)
        });
      };

      const showDeleteModal = () => {
        modalDeleteRef.value.showModal();
      };
      const hideDeleteModal = () => {
        modalDeleteRef.value.hideModal();
      };

      return {
        updateTranslation,
        idsToDelete,
        onSelectCheckbox,
        submitDelete,
        errorMessage,
        orderByOptions,
        searchText,
        filterRows,
        selectAll,
        searchByText,
        showDeleteModal,
        modalDeleteRef
      }
    }
  }
</script>

<style scoped lang="stylus">
  @import "../Styles/translation_list.styl";
</style>
