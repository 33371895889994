const FILE_TYPE = [
    {id: 1, value: 'Key Terms'},
    {id: 2, value: 'Audio Files'},
]

const FILES = {
    KEY_TERMS: 'Key Terms',
    AUDIO_FILES: 'Audio Files'
}

const USER_ROLES = {
    SUPER_ADMINISTRATOR: 'SUPER_ADMIN',
    ADMINISTRATOR: 'ADMIN',
    LANGUAGE_EDITOR: 'LANGUAGE_EDITOR'
}

export default {FILE_TYPE, FILES, USER_ROLES}