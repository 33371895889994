<template>
  <div class="progress-circle" :class="[`p${percentage}`, (percentage > 50) ? 'over50' : '']">
    <span class="percentage-text flex justify-center items-center">
      <span class="percentage-bold">{{ percentage }}</span>
      <span>%</span>
    </span>
    <div class="left-half-clipper">
      <div class="first50-bar"></div>
      <div class="value-bar"></div>
    </div>
  </div>
</template>

<script>
//  import { onMounted } from 'vue'

  export default {
    name: 'RadialProgress',
    props: {
      percentage: {
        type: [String, Number],
        default: 0
      }
    },
  }
</script>

<style scoped lang="stylus">
  @import "styles/radial_progress.styl";
</style>
