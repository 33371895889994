import { createRouter, createWebHistory } from 'vue-router'
import DashboardRoutes from '../modules/Dashboard/routes'
import ProjectRoutes from '../modules/Project/routes'
import SecurityRoutes from '../modules/Security/Routes'
import { Storage } from '../core/utils'

let routes = [];

routes = routes.concat(DashboardRoutes, ProjectRoutes, SecurityRoutes);

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {

  // redirect to login page if not logged in and trying to access a restricted page
  const loggedIn = !!Storage.getAccessToken();
  if (to.meta.authRequired && !loggedIn) {
    return next({name: 'login-page'})
  } else if ((to.name === 'login-page' || to.name === 'callback-page')&& loggedIn) {
    return next({name: 'dashboard-page'})
  } else {
    next();
  }
})


export default router;
