<template>
  <div class="relative custom-dropdown-container">
    <div class="dropdown-label" v-if="label">{{ label }}</div>

    <button @click.stop="show"
            class="custom-dropdown-btn inline-flex justify-between items-center">
      <div class="inline-block dropdown-selected-value">{{ selectedOptionText }}</div>
      <div class="inline-block dropdown-toggle-icon"></div>
    </button>

    <div class="custom-dropdown-menu-container relative z-50" v-if="opened"  v-click-outside="hide">
      <div class="absolute custom-dropdown-wrapper">
        <div class="custom-dropdown-menu">
          <button class="dropdown-menu-item"
                  tabindex="0"
                  v-for="(opt, index) in options"
                  :key="`drop-item-index-${index}`" @click="selectOption(opt)">
            <span>{{ opt.text || opt.name }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'Dropdown',
    props: {
      label: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default: () => {
          return []
        }
      },
      defaultVal: {
        type: [Object, String],
        default: null
      }
    },
    data() {
      return {
        opened: false,
        selectedOpt: null
      }
    },
    computed: {
      selectedOptionText(){
        if(this.selectedOpt) {
          return this.selectedOpt.text || this.selectedOpt.name;
        }
        return this.placeholder
      }
    },
    methods:{
      show: function () {
        this.opened = !this.opened;
      },
      hide: function () {
        this.opened = false
      },
      selectOption(opt) {
        this.selectedOpt = opt;
        this.hide();
        this.$emit("onSelectOption", opt)
      }
    },
    beforeMount() {
      if (this.defaultVal) {
        if (this.defaultVal.name) {
          this.selectedOpt = this.defaultVal
        } else {
          this.selectedOpt = this.options.find((opt) => {
            return opt.value === this.defaultVal
          })
        }
      }
    },
  }
</script>

<style scoped lang="stylus">
  @import "styles/dropdown.styl";
</style>
