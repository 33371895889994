import { createApp } from 'vue'
import VueNextSelect from 'vue-next-select'
import 'vue-next-select/dist/index.css'
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'

import "tailwindcss/tailwind.css"

import './core/styles/main.styl'
import router from './router'
import { ClickOutside } from './core/directive'
import App from './App.vue'
import store from './store';
const app = createApp(App);

app.use(store);
app.use(router);
app.directive('click-outside', ClickOutside);

const oktaAuth = new OktaAuth({
  issuer: `https://${process.env.VUE_APP_OKTA_DOMAIN ? process.env.VUE_APP_OKTA_DOMAIN : ""}/oauth2/default`,
  clientId: process.env.VUE_APP_OKTA_APP_CLIENT_ID ? process.env.VUE_APP_OKTA_APP_CLIENT_ID : "",
  redirectUri: `${window.location.origin}${router.resolve({name: 'callback-page'}).href}`,
  scopes: ['openid', 'profile', 'email'],
  restoreOriginalUri: async () => {
  }
});
app.use(OktaVue, { oktaAuth });

app.mount('#app');
app.component('vue-select', VueNextSelect);
