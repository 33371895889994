import SecurityView from "../SecurityView.vue"
import LoginPage from "../Pages/LoginPage.vue"
import LoginCallback from "../Pages/LoginCallback.vue"

const routes = [
    {
        path: '/login',
        component: SecurityView,
        children: [
            {
                path: '',
                name: 'login-page',
                component: LoginPage,
            },
        ]
    },
    {
        path: '/callback',
        component: SecurityView,
        children: [
            {
                path: '',
                name: 'callback-page',
                component: LoginCallback,
            },
        ]
    },
]

export default routes
