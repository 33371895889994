<template>
  <div class="bg-white rounded-xl p-4 md:p-7 flex flex-col justify-between project-card" @click="goToProjectPage" :title="project.name">

    <div class="flex justify-center">
      <RadialProgress :percentage="project.progress"/>
    </div>

    <div class="">
      <div class="project-card__wrapper-title flex mb-2 items-center">
        <h2 class="project-card__title inline-block">{{ project.name }}</h2>
        <a v-if="!isLanguageEditor" href="javascript:void(0)" class="inline-block project-card__edit-text common-link"
           @click.stop="toggleModal(true, project, projectIndex)">Edit</a>
      </div>
      <div class="project-card__wrapper-languages" >
        {{ getLanguagesText(project.languages) }}
      </div>
    </div>
  </div>
</template>

<script>

  import { useRouter } from 'vue-router'
  import RadialProgress from './RadialProgress.vue'
  import {computed} from "vue";
  import {Constants} from "@/core/utils";
  import { useFacilityStore } from "@/store";


  export default {
    name: 'ProjectCard',
    components:{ RadialProgress},
    props: {
      project: {
        type: Object,
        required: false,
        default:() => {
          return {}
        }
      },
      projectIndex: {
        type: Number,
        default:0
      }
    },
    setup(props, { emit }) {

      const router = useRouter();
      const facilityStore = useFacilityStore();
      const isLanguageEditor = computed(() => {
        return props.project.application_role === Constants.USER_ROLES.LANGUAGE_EDITOR
      })

      const getLanguagesText = (languages) => {
        let languagesText = '';

        languages.map((lang, index) => {
          languagesText +=  lang.culture_display_name;
          if( index !== (languages.length - 1) ){
            languagesText += ', ';
          }
        });

        return languagesText;
      };

      const toggleModal = (show, project, pIndex) => {
        emit('toggleModal', show, project, pIndex)
      };

      const goToProjectPage = (event) => {
        event.preventDefault();
        facilityStore.$reset();
        router.push({
          name: 'project-page',
          params: {
            id: props.project.id
          }
        })
      };

      return {
        isLanguageEditor,
        getLanguagesText,
        toggleModal,
        goToProjectPage
      }
    }
  }
</script>

<style scoped lang="stylus">

</style>
