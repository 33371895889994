<template>
  <div class="mb-3">
    <div class="wrapper-language-card p-7 flex items-align justify-between"
         :class="{'default-lang': isDefaultLang}" @click="goToEditLanguage">
      <div class="inline-flex language-mane">
        <span class="mr-1">{{ language.culture_display_name }} ({{ language.culture_name }})</span>
        <span v-if="isDefaultLang"> - <strong>Default</strong> ({{ parseInt(termCount) == 1 ? `1 key term` : `${termCount} key terms` }})</span>
      </div>
      <div class="inline-flex justify-end options-card">
        <div @click.stop="handleImportXLIFF" class="options-card-item mr-4 flex justify-center flex-row items-center">
          <div class="option-img import"></div>
          <div class="label-option">IMPORT</div>
        </div>
        <div @click.stop="handleExportXLIFF" class="options-card-item mr-4 flex justify-center flex-row items-center">
          <div class="option-img export"></div>
          <div class="label-option">EXPORT</div>
        </div>
        <div v-if="!isDefaultLang" class="options-card-item mr-4 flex justify-center flex-row items-center">
          <div class="option-img is-edit-lang"></div>
          <div class="label-option">EDIT</div>
        </div>
        <div v-if="!isDefaultLang" v-show="!isLanguageEditor" class="options-card-item flex justify-center flex-row items-center" @click.stop="showDeleteModal">
          <div class="option-img is-delete-lang"></div>
          <div class="label-option">DELETE</div>
        </div>
      </div>
    </div>
    <div class="common-error-message" v-if="errorMessage">{{ errorMessage }}</div>

    <ImportModal @onSuccess="onSuccessImport" ref="modalImportRef" :facility-id="facilityId" :project-id="projectId" :language="language"/>

    <DeleteLanguageModal
      v-if="!isDefaultLang"
      ref="modalDeleteRef"
      @deleteLanguage="deleteLanguage()"/>
  </div>
</template>

<script>

import {ref} from 'vue'
import {useRouter} from 'vue-router'

import {Spinner} from "@/core/utils"
import {ProjectServices} from "../Services"
import DeleteLanguageModal from "./DeleteLanguageModal.vue"
import ImportModal from "@/modules/Project/Components/ImportModal.vue";

export default {
  name: 'LanguageCard',
  components: {ImportModal, DeleteLanguageModal},
  props: {
    language: {
      type: Object,
      default: () => {
        return {}
      }
    },
    projectId: {
      type: [String, Number],
      default: ""
    },
    facilityId: {
      type: [String, Number],
      default: null,
    },
    isDefaultLang: {
      type: Boolean,
      default: false
    },
    termCount: {
      type: [String, Number],
      default: "0"
    },
    isLanguageEditor: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const router = useRouter();
    const errorMessage = ref("");

    const modalImportRef = ref(null)
    const modalDeleteRef = ref(null);

    const goToEditLanguage = () => {
      router.push({
        name: 'language-page',
        params: {
          id: props.projectId,
          languageId: props.language.id,
          facilityId: props.facilityId,
        }
      })
    };

    const handleImportXLIFF = () => {
      modalImportRef.value.showModal();
    }

    const onSuccessImport = () => {
      emit('onSuccessImport');
    }

    const handleExportXLIFF = async () => {
      Spinner.start()
      ProjectServices.export(props.projectId, props.language.id, props.facilityId).then(response => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `export_${props.language.culture_display_name}_${props.language.culture_name}.xliff`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).finally(() => {
        Spinner.stop()
      })
    }

    const deleteLanguage = async () => {
      try {
        Spinner.start();
        if (props.isDefaultLang) {
          return;
        }

        await ProjectServices.deleteLanguage(props.projectId, props.facilityId, props.language.id);
        emit('onDeleteLanguage');
      } catch (e) {
        errorMessage.value = e;
        setTimeout(() => {
          errorMessage.value = ""
        }, 5000);
      } finally {
        modalDeleteRef.value.hideModal();
        Spinner.stop();
      }
    };

    const showDeleteModal = () => {
      modalDeleteRef.value.showModal();
    };

    return {
      goToEditLanguage,
      deleteLanguage,
      errorMessage,
      modalDeleteRef,
      showDeleteModal,
      handleExportXLIFF,
      handleImportXLIFF,
      modalImportRef,
      onSuccessImport
    }
  }
}
</script>

<style scoped lang="stylus">
@import "../Styles/language_card.styl";
</style>
