<template>
  <Modal ref="modalRef" :modal-title="isEdit ? 'Edit Project' : 'Add New Project'" @onHideModal="resetForm">
    <template v-slot:modalContent>
      <form @submit.prevent="submitForm">
        <div class="common-form-group">
          <label for="name" class="common-label-input">Project name</label>
          <input type="text" id="name" class="common-input" v-model="form.name" maxlength="100">
          <div class="common-error-message" v-if="nameErrorMessage">{{ nameErrorMessage }}</div>
        </div>

        <div class="common-form-group">
          <label for="origin" class="common-label-input">
            Enter the domain or subdomain only, example: yourcompany.com, do not enter HTTP:// or HTTPS://
          </label>
          <input type="text" id="origin" class="common-input" v-model="form.origin" maxlength="100">
          <div class="common-error-message" v-if="originErrorMessage">{{ originErrorMessage }}</div>
        </div>

        <div class="common-form-group" v-if="isEdit">
          <div class="common-label-input">Default language</div>
          <div class="common-input disabled">{{ form.defaultLanguage }}</div>
        </div>
        <div class="common-form-group" v-if="isEdit">
          <div class="common-label-input">API key</div>

          <div class="common-input disabled flex items-center justify-between">
            <input type="text" readonly id="apiKey" :value="form.secretKey" class="text-api-key">
            <div class="common-link api-key-copy" @click="copyAPIKey">Copy</div>
          </div>

          <div class="common-success-message" v-if="successCopiedMessage">{{ successCopiedMessage }}</div>
        </div>

        <div class="common-form-group">
          <label for="description" class="common-label-input">Project description</label>
          <textarea id="description" class="common-input is-textarea" rows="4" v-model="form.description" maxlength="100"></textarea>
          <div class="common-error-message" v-if="descErrorMessage">{{ descErrorMessage }}</div>
        </div>

        <div class="common-error-message mt-3" v-if="serviceErrorMessage">{{ serviceErrorMessage }}</div>

        <div class="flex justify-end items-center container-modal-btns">
          <button @click="hideModal" class="common-btn common-btn--secondary mr-3">Cancel</button>
          <button type="submit" class="common-btn">{{ isEdit ? 'Edit' : 'Add' }}</button>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>

  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { Spinner, Utils} from "@/core/utils"

  import { DashboardServices } from "../Services"
  import { Modal } from '@/core/components'

  export default {
    name: 'AddEditProjectModal',
    components: { Modal },
    props: {
      isEdit: {
        type: Boolean,
        default: false
      }
    },
    setup(props, { emit }) {
      const router = useRouter();

      const modalRef = ref(null);
      const isEdit = ref(props.isEdit);
      const project = ref({});
      const projectIndex = ref(0);

      const nameErrorMessage = ref(null);
      const descErrorMessage = ref(null);
      const originErrorMessage = ref(null);
      const serviceErrorMessage = ref(null);
      const successCopiedMessage = ref(null);

      const form = ref({
        name: "",
        description: "",
        secretKey: "",
        defaultLanguage: "",
        origin: ""
      });

      const hideModal = () => {
        resetForm();
        modalRef.value.hideModal();
      };

      const showModal = (projectObj, newProjectIndex) => {
        if(projectObj && projectIndex){
          project.value = projectObj;
          projectIndex.value = newProjectIndex;
          populateForm(projectObj);
        }
        modalRef.value.showModal();
      };

      const resetForm = () => {
        form.value.name = "";
        form.value.description = "";
        form.value.secretKey = "";
        form.value.defaultLanguage = "";
        form.value.origin = "";

        project.value = {};
        resetErrorMessages();
      };

      const resetErrorMessages = () => {
        nameErrorMessage.value = null;
        descErrorMessage.value = null;
        originErrorMessage.value = null;
        serviceErrorMessage.value = null;
      };

      const prepareDataToSubmit = () => {
        return {
          name: form.value.name,
          description: form.value.description,
          origin: form.value.origin
        };
      };

      const setServiceErrorMessage = (error) => {
        if(Array.isArray(error)) {
          serviceErrorMessage.value = error[0].msg
        } else {
          if( error.message ){
            serviceErrorMessage.value = error.message
          } else if (error.msg) {
            serviceErrorMessage.value = error.msg
          } else {
            serviceErrorMessage.value = error
          }
        }
      };

      const addProjectSubmit = () => {
        Spinner.start();

        const data = prepareDataToSubmit();

        DashboardServices.addProject(data).then((response) => {
          const defaultLang = getDefaultLanguage(response);
          Spinner.stop();
          const payload = {
            projectId: response.id,
            languageId: defaultLang.id,
            facilityId: response.national_facility.id,
          };
          goToEditLanguage(payload, defaultLang.id);
        }).catch((error) => {
          Spinner.stop()
          setServiceErrorMessage(error);
        })
      };

      const editProjectSubmit = () => {
        Spinner.start();
        const data = prepareDataToSubmit();

        DashboardServices.editProject(project.value.id, data).then((response) => {
          hideModal();
          emit("onEditProject", projectIndex.value, response);
          Spinner.stop();
        }).catch((error) => {
          Spinner.stop();
          setServiceErrorMessage(error);
        })
      };

      const validForm = () => {
        resetErrorMessages();
        let validForm = true;

        if(isEdit.value === true && !project.value.id) {
          serviceErrorMessage.value = 'Incorrect Project ID.';
          validForm = false;
        }

        if(form.value.name.length == 0 || form.value.name.trim() == "") {
          nameErrorMessage.value = 'Required field.';
          validForm = false;
        } else if (form.value.name.length > 100) {
          nameErrorMessage.value = 'This field must have at most 100 characters.';
          validForm = false;
        }

        if(form.value.origin && form.value.origin.length > 0 && !Utils.validDomain(form.value.origin)) {
          originErrorMessage.value = 'Please enter a valid domain.';
          validForm = false;
        }

        if (form.value.description && form.value.description.length > 100) {
          descErrorMessage.value = 'This field must have at most 100 characters.';
          validForm = false;
        }
        return validForm;
      };

      const submitForm = () => {

        if(!validForm()){
          return
        }

        if(isEdit.value === true && project.value.id){
          editProjectSubmit();
        }else {
          addProjectSubmit();
        }
      };

      const getDefaultLanguage = (project) => {

        let defaultLang = {};

        if(project && project.languages){
          defaultLang = project.languages.find((lang) => {
            return lang.culture_name === project.default_language;
          });
        }

        return defaultLang && defaultLang.id ? defaultLang : {}
      };

      const populateForm = (project) => {
        if(isEdit.value === true){
          form.value.name = project.name;
          form.value.description =  project.description;
          form.value.secretKey = project.secret_key;
          form.value.origin = project.origin;

          const defaultLanguage = getDefaultLanguage(project);
          form.value.defaultLanguage = defaultLanguage ? `${defaultLanguage.culture_display_name} (${defaultLanguage.culture_name})` : '';
        }
      };

      const copyAPIKey = () => {
        const copyText = document.getElementById("apiKey");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");

        successCopiedMessage.value = 'Copied.';
        setTimeout(() => {
          successCopiedMessage.value = null
        }, 1000)

      };

      const goToEditLanguage = (payload) => {
        router.push({
          name: 'language-page',
          params: {
            id: payload.projectId,
            languageId: payload.languageId,
            facilityId: payload.facilityId,
          }
        })
      };

      return {
        form,
        modalRef,
        submitForm,
        hideModal,
        showModal,
        copyAPIKey,
        nameErrorMessage,
        descErrorMessage,
        serviceErrorMessage,
        successCopiedMessage,
        resetForm,
        originErrorMessage
      }
    }
  }
</script>

<style scoped lang="stylus">
  @import "~@/core/styles/variables.styl"
  @import "~@/core/styles/mixins.styl"

  .text-api-key {
    max-width: calc(100% - 40px);
    width: calc(100% - 40px);
    text-ellipsis();
    background-color: transparent;
    padding: 0px;
    margin: 0px;
  }
  .api-key-copy {
    margin-right: -10px;
  }
</style>
