import DashboardView from "../DashboardView.vue"
import DashboardPage from "../Pages/DashboardPage.vue"

const routes = [
  {
    path: '/',
    component: DashboardView,
    children: [
      {
        path: '',
        name: 'dashboard-page',
        component: DashboardPage,
        meta: {authRequired: true},
      }
    ]
  }
]

export default routes
