<template>
  <div class="wrapper-add-term-form mb-4">
    <div class="container mx-auto">
      <form @submit.prevent="handleSubmitForm">
        <div class="card-term-form p-5 pb-6 bg-white">
          <div class="md:flex items-align">
            <div class="common-form-group md:mr-5 md:max-w-360">
              <label for="key_term" class="common-label-input">New key term*</label>
              <textarea type="text" id="key_term" class="common-input is-textarea add-term-form-textarea" v-model="keyTerm"></textarea>
            </div>
            <div class="common-form-group md:mr-2">
              <label for="val_term" class="common-label-input">English translation*</label>
              <textarea type="text" id="val_term" class="common-input is-textarea add-term-form-textarea" v-model="valueTerm"></textarea>
            </div>
            <button type="submit" class="common-btn btn-add-term">Add</button>
          </div>
          <div v-if="errorMessage" class="common-error-message mt-3">
            {{ errorMessage }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

  import { ref} from 'vue'

  import { Spinner } from "@/core/utils"
  import { ProjectServices } from "../Services"

  export default {
    name: 'AddTermForm',
    props: {
      projectId: {
        type: [String, Number],
        default: "",
        required: true
      },
      languageId: {
        type: [String, Number],
        default: "",
        required: true
      },
      facilityId: {
        type: [String, Number],
        default: null,
      },
    },
    setup(props, { emit }) {

      const keyTerm = ref("");
      const valueTerm = ref("");
      const errorMessage = ref("");

      const resetErrorMessages = () => {
        errorMessage.value = "";
      };

      const resetForm = () => {
        keyTerm.value = "";
        valueTerm.value = "";
        errorMessage.value = "";
      };

      const addTranslation = async () => {
        try {
          resetErrorMessages();
          Spinner.start();

          const data = {
            key: keyTerm.value,
            value: valueTerm.value
          };
          const response = await ProjectServices.addUpdateTranslation(props.projectId, props.languageId, props.facilityId, data);
          emit('addTranslation', response);
          resetForm();
        } catch (e) {
          errorMessage.value = e;
        } finally {
          Spinner.stop();
        }
      };

      const handleSubmitForm = () => {
        addTranslation();
      };

      return {
        keyTerm,
        valueTerm,
        handleSubmitForm,
        errorMessage
      }
    }
  }
</script>

<style scoped lang="stylus">
  @import "../Styles/add_term_form.styl";
</style>
