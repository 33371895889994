<template>
  <div class="mt-7 sm:pl-7">
    <div v-if="showDropdown">
      <div class="flex items-center sm:items-end flex-col sm:flex-row lg:w-1/3">
        <div class="container-dropdown mb-4 sm:mb-0">
          <CustomSelect
            label="Add new language"
            placeholder="Select language"
            :options="languagesOpts"
            @onSelectOption="onSelectOption"/>
        </div>
        <button class="common-btn btn-submit-add-lang ml-2" @click="submitForm">Add</button>
      </div>
      <div v-if="errorMessage" class="common-error-message">{{ errorMessage }}</div>
    </div>
    <div v-else-if="!isLanguageEditor" class="flex justify-end">
      <button class="common-btn btn-add-language" @click="toggleDropdown">Add Language</button>
    </div>
  </div>
</template>

<script>

  import { ref, computed } from 'vue'

  import { CustomSelect } from '@/core/components'
  import { Spinner } from "@/core/utils"
  import { ProjectServices } from "../Services"

  export default {
    name: 'AddNewLanguage',
    components: { CustomSelect },
    props: {
      project: {
        type: Object,
        default: () => {
          return {}
        }
      },
      isLanguageEditor: {
        type: Boolean,
        default: false
      }
    },
    setup(props, { emit }) {

      const showDropdown = ref(false);
      const errorMessage = ref("");
      const newLanguageId = ref(null);
      const languages = ref([]);

      const resetErrorMessages = () => {
        errorMessage.value = "";
      };

      const toggleDropdown = () => {
        showDropdown.value = !showDropdown.value;
      };

      const getLanguages = () => {
        ProjectServices.getLanguages().then((response) => {
          languages.value = response;
        })
      };

      const getLanguagesOpts = () => {
        return languages.value.map((lang) => {
          return {
            value: lang.id,
            text: `${lang.culture_display_name} (${lang.culture_name})`
          }
        });
      };

      const languagesOpts = computed(getLanguagesOpts);

      const validForm = () => {
        let valid = true;
        if(newLanguageId.value == null) {
          valid = false;
          errorMessage.value = "Please select an option."
        }

        return valid;
      };

      const addNewLanguage = () => {
        Spinner.start();
        resetErrorMessages();

        ProjectServices.addNewLanguage(props.project.id, newLanguageId.value).then((response) => {
          emit('addNewLanguage', response);
          newLanguageId.value = null;
          showDropdown.value = false;
          Spinner.stop();
        }).catch(() => {
          errorMessage.value = "Unexpected error, please try again.";
          Spinner.stop();
        });
      };

      const submitForm = () => {
        if(!validForm()){
          return
        }
        addNewLanguage();
      };

      const onSelectOption = (opt) => {
        newLanguageId.value = opt.value;
      };

      getLanguages();

      return {
        showDropdown,
        submitForm,
        errorMessage,
        languagesOpts,
        toggleDropdown,
        onSelectOption
      }
    }
  }
</script>

<style scoped lang="stylus">
  .btn-add-language
    padding-left 30px
    padding-right 30px
  .btn-submit-add-lang
    min-width 85px
    max-height 40px
  .container-dropdown
    width calc(100% - 95px)
</style>