<template>
  <header class="relative bg-blue">
    <nav class="bg-white py-5">
      <div class="container px-4 mx-auto flex items-center">

        <div class="flex justify-between items-center">
          <a href="/" class="container-logo-ja">
            <img src="~@/core/assets/ja-logo.svg" alt="Junior Achievement">
          </a>
        </div>

        <div class="flex flex-row ml-auto mt-0" id="navbar-collapse" v-if="isLoggedIn">
          <div class="relative container-dropdown-header">
            <button type="button" id="toggleDrop" class="justify-end common-dropdown-btn dropdown-header"
                    :class="{'active': dropdownIsOpen}" @click="toggleDropdown">
              <span class="text-dropdown">{{userLocal.given_name}} {{userLocal.family_name}}</span>
              <span class="toggle-icon-dropdown"></span>
            </button>

            <div class="absolute right-0 w-56 mt-2 bg-white border border-gray-20
            divide-y divide-gray-100 rounded-md flex-col z-10" :class="dropdownClasses" id="dropContent">
              <div class="px-4 py-3">
                <p class="text-sm leading-5">Signed in as</p>
                <p class="text-sm font-medium leading-5 text-gray-900 truncate">{{userLocal.email}}</p>
              </div>
              <div class="py-1">
                <a href="javascript:void(0)" @click="logoutUser" tabindex="3" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" >Sign out</a></div>
            </div>

          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
  import {onMounted, ref, computed } from 'vue'
  import {useAuth} from '@okta/okta-vue';
  import { Storage } from '@/core/utils'
  import { SecurityService } from '@/modules/Security/Services'
  import router from '@/router'

  export default {
    name: "HeaderApp",
    setup(){
      const dropdownIsOpen = ref(false);
      const userLocal = ref({});
      const isLoggedIn = ref(false);

      const dropdownClasses = computed(() => {
        return {
          hidden: !dropdownIsOpen.value,
          flex: dropdownIsOpen.value
        }
      });

      const toggleDropdown = () => {
        dropdownIsOpen.value = !dropdownIsOpen.value
      };

      const logoutUser = async () => {
        SecurityService.logout();
        const $auth = useAuth();
        await $auth.signOut();
        await router.push({name: 'login-page'});
      };

      onMounted(() => {
        const accessToken = Storage.getAccessToken();
        if (accessToken){
          userLocal.value = Storage.getUser();
          isLoggedIn.value = true;
        }
      });

      return {
        dropdownClasses,
        toggleDropdown,
        dropdownIsOpen,
        logoutUser,
        userLocal,
        isLoggedIn
      }
    },
  };
</script>

<style lang="stylus" scoped>
  @import "styles/header.styl";
</style>
